import React, {useState, useEffect} from 'react'
import Img from "gatsby-image";
// components
import HeaderQuery from "../../components/header/header";
import BasicBlock from "../../components/basicBlock/basicBlock";
import CardOneTechno from "../../components/card/cardOneTechno";
import Footer from "../../components/footer/footer";
// style
import './oneProjet.css'
import {Helmet} from "react-helmet";

const OneProjet = (props) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaMatch = window.matchMedia('(min-width: 768px)');
    setMatches(mediaMatch.matches)

    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  const styles = {
    containerPicture: isRowBased => ({
      gridTemplateColumns: isRowBased
        ? `repeat(${props.pageContext.page.number_cols * 2}, 1fr)`
        : `repeat(${props.pageContext.page.number_cols}, 1fr)`
    })
  };

  console.log('props.loc', props.location)
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="fr" />
        <title>Agence</title>
        <link rel="canonical" href={"https://webdevvision.fr/projets/" + props.location.pathname} />
        <meta
          name="description"
          content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
        />
      </Helmet>
      <HeaderQuery />
      <div className="one-projet-container-top-image">
        <div className="one-projet-foreground" />
        <div className="one-projet-title-container">
          <h3 className={`one-projet-subtitle ${props.pageContext.page.type_projet.color}`}>{props.pageContext.page.type_projet.name}</h3>
          <h2 className="tertiary">{props.pageContext.page.title}</h2>
        </div>
        <Img imgStyle={{height: 500}} fluid={props.pageContext.page.header_img.childImageSharp.fluid}  />
      </div>
      <BasicBlock
        containerRight={() => (
          <>
            <h4 className="primary one-projet-block-title">FONCTIONNALITÉS</h4>
            <p className="secondary pb-32">{props.pageContext.page.info_functionality}</p>
            <h4 className="primary one-projet-block-title">TECHNOLOGIES</h4>
            <CardOneTechno data={props.pageContext.page.technos} noPadding/>
            {props.pageContext.page.url_link !== null && (
              <>
                <div className="pb-32" />
                <h4 className="primary one-projet-block-title">EN VOIR UN PLUS</h4>
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="one-projet-button"
                  href={props.pageContext.page.url_link}>Découvrir
                </a>
              </>
            )}
          </>
        )}
        containerLeft={() => (
          <>
            <h4 className="primary one-projet-block-title">LE CLIENT</h4>
            <p className="secondary pb-32">{props.pageContext.page.info_client}</p>
            <h4 className="primary one-projet-block-title">LE PROJET</h4>
            <p className="secondary">{props.pageContext.page.info_projet}</p>
          </>
        )}
        containerRightFirstOnMobile={false}
        containerRightContainPicture={false}
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <div
        className="one-projet-pictures-container"
        style={styles.containerPicture(matches)}
      >
        {props.pageContext.page.medias.map(item => (
          <img
            alt=""
            className="one-projet-picture"
            src={process.env.STRAPI + item.url} />
        ))}
      </div>
      <Footer path={props.path} />
    </main>
  )
}

export default OneProjet
